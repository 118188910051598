.page-login-body-left {
    border-radius: 15px;
    background: linear-gradient(to right, rgba(0, 255, 187, 0.5), rgba(8, 0, 255, 0.27));
}
.page-login-body-left-flashing-element {
    animation: flash 10s infinite; /* 动画名称，持续时间，重复次数 */
    border: 0px solid #ccc; /* 边框，可以自定义 */
}
.page-login-body-left-streamlined-effect {
    color: white; /* 文字颜色 */
    text-align: center; /* 文字居中 */
    font-weight: bold; /* 字体加粗 */
}
/* 定义关键帧动画 */
@keyframes flash {
    0%, 100% {
        background-color: transparent; /* 开始和结束时背景透明 */
        opacity: 1; /* 开始和结束时完全不透明 */
    }
    50% {
        background-color: gold; /* 闪光时的背景颜色 */
        opacity: 0.8; /* 闪光时的透明度 */
    }
}
.page-login-title {
    position: absolute;
    top: 59vh;
    left: calc(2vw + 10px);
    height: 5vw;
    font-size: 2vw;
}
.title-title {
    position: fixed;
    left: 4.5vw;
    top: 67vh;
    width: 12vw;
    height: 5vw;
    text-align: left; /* 水平居中 */
    vertical-align: middle; /* 垂直居中 */
}
.title-title h1 {
    font-size: 4vw;
}
.title-describe {
    position: fixed;
    left: calc(4.5vw + 5px);
    top: calc(67vh + 7vw + 30px);
    width: 19vw;
    text-align: left; /* 水平居中 */
    vertical-align: middle; /* 垂直居中 */
}
/* 应用打字机动画到元素 */
.typewriter-effect {
    position: inherit;
    white-space: nowrap; /* 保持文本在一行显示 */
    overflow: hidden; /* 隐藏超出部分 */
    font-size: 1.4vw;
    border-right: 2px solid; /* 显示光标 */
    animation:
            typing 5s steps(14, end),
            blink-caret 0.8s step-end infinite;
}
/* 定义打字机动画的关键帧 */
@keyframes typewriter {
    from {
        width: 0; /* 动画开始时，文本长度为0 */
    }
    to {
        width: 19vw; /* 动画结束时，文本长度为100% */
    }
}
/* 定义光标闪烁的关键帧 */
@keyframes blink-caret {
    from, to {
        border-color: transparent; /* 光标不可见 */
    }
    50% {
        border-color: white; /* 光标可见 */
    }
}

.page-login-body-right {
    border: 1px solid mediumpurple;
    border-radius: 15px;
    background-color: white ;
}
.page-login-body-right-title {
    position: fixed;
    top: 23vh;
    left: 73vw;
}
.page-login-body-right-title h2 {
    font-size: 1.8vw;
    color: rgba(0, 81, 255, 0.59);
}
.page-login-body-right-forum {
    position: fixed;
    top: 32vh;
    left: 73vw;
    width: 21vw;
    height: 15vh;
    /*border: #5d73e6 1px solid;*/
    display: grid;
    grid-template-columns: 21vw;
    gap: 1vw 1vh;
}
.page-login-body-right-forum span {
    position: relative;
    display: inline-block;
    margin-top: 1.4vh;
    /*margin-left: 0.5vw;*/
    padding: 0;
    height: 3.2vh;
    width: 23.5vw;
    /*border: #5d73e6 1px solid;*/
}

.page-login-body-right-forum input {
    height: 4vh;
    width: 21vw;
    border-top: white 1px solid;
    border-bottom: hotpink 1px solid;
    border-left: white 1px solid;
    border-right: white 1px solid;
    padding-left: 5px;
    /*border-radius: 5px;*/
    font-size: 1.35vh;
}

.page-login-body-right-forum button {
    height: 3.6vh;
    width: 21vw;
    margin-top: 1vh;
    /*padding: 1vh;*/
    background: linear-gradient(to right, rgba(0, 81, 255, 0.59), rgba(0, 140, 255, 0.52));
    border: white 1px solid;
    border-radius: 5px;
    font-size: 1.35vh;
    font-weight: bold;
    color: white;
}

.page-login-body-right-help-panel {
    position: fixed;
    height: 12vh;
    width: 21vw;
    left: 73vw;
    top: 55vh;
    display: grid;
    grid-template-columns: 6vw 2vw 10vw;
    grid-row-gap: 0;
    grid-column-gap: 1vw;
}

.page-login-body-right-third-login {
    position: fixed;
    height: 12vh;
    width: 21vw;
    left: 73vw;
    top: 57vh;
    display: grid;
    grid-template-columns: 6vw 1vw 11vw;
    grid-row-gap: 0;
    grid-column-gap: 1vw;
}

.page-login-body-right-agreements {
    position: fixed;
    left: 73vw;
    top: 87vh;
    width: 21vw;
}

.page-login-body-right-help-panel span,
.page-login-body-right-third-login span {
    padding: 0;
    height: 3vh;
}

.page-login-body-right-help-panel :nth-child(3),
.page-login-body-right-third-login :nth-child(3) {
    text-align: right;
}

.page-login-body-right-help-panel p,
.page-login-body-right-third-login p,
.page-login-body-right-agreements p,
.page-login-body-right-help-panel a,
.page-login-body-right-third-login a,
.page-login-body-right-agreements a {
    font-size: 1vh;
}

.page-login-body-right-help-panel a,
.page-login-body-right-third-login a,
.page-login-body-right-agreements a {
    font-size: 1vh;
    font-weight: bold;
    text-decoration: none;
    color: #000000;
}

.page-login-body-right-help-panel a:link,
.page-login-body-right-third-login a:link,
.page-login-body-right-agreements a:link,
.page-login-body-right-help-panel a:active,
.page-login-body-right-third-login a:active,
.page-login-body-right-agreements a:active  {
    color: #000000;
}
.page-login-body-right-help-panel a:hover,
.page-login-body-right-third-login a:hover,
.page-login-body-right-agreements a:hover {
    color: rgba(0, 81, 255, 0.59);
}

.page-login-body-right-help-panel a:active,
.page-login-body-right-third-login a:active,
.page-login-body-right-agreements a:active {
    color: rgba(0, 81, 255, 0.59);
}

