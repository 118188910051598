.navigator-link {
    font-size: 1.5vh;
    font-weight: bold;
    text-decoration: none;
}

.navigator-link:link, .navigator-link:visited {
    color: #000000;
}

.navigator-link:hover {
    color: #5d73e6;
}

.navigator-link:active {
    color: #5d73e6;
}