.dot-button {
    z-index: 9998;
    text-align: center; /* 水平居中 */
    vertical-align: middle; /* 垂直居中 */
}

.dot-button img {
    height: 1vw;
    width: 1vw;
    background-color: ghostwhite; /* 背景颜色 */
    border: none;                 /* 边框 */
    border-radius: 0.5vw;         /* 圆角边框 */
    cursor: pointer;              /* 鼠标悬停时显示指针手势 */
    outline: none;                /* 点击时不显示轮廓 */
    opacity: 0.5;                 /* 透明度为0.5 */
    z-index: 9999;
}

/* 轮播图容器样式 */
.banner-carousel {
    /*position: relative;*/
    overflow: hidden;
}

/* 轮播项样式 */
.banner-carousel > div {
    /*position: absolute;*/
    /*width: 100%;*/
    transition: opacity 0.8s ease-in-out; /* 设置渐变动画 */
    opacity: 0;                           /* 初始透明度 */
}

/* 激活的轮播项 */
.banner-carousel > div.active {
    opacity: 1; /* 可见 */
}
