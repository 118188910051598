.card {
    background: #f1eaf5;
    border-radius: 10px;
    /*border-style: solid;*/
    /*border-width: 0.5px;*/
    /*border-color: #5d73e6;*/
    /*display: inline-block;*/
    width: 21.4vw;
    height: 6vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-top: 0.3vw;
}
.card-left {
    display: inline-block;
    width: 6vw;
    height: 6vw;
}
.card-right {
    width: 14vw;
    height: 6vw;
    margin-left: 6.6vw;
    margin-top: -6vw;
}
.card-right-title {
    display: inline-block;
    height: 1.5vw;
    width: 14vw;
    top: 0.1vw;
}
.card-right-description {
    display: inline-block;
    height: 4.7vw;
    width: 14vw;
    margin-top: 0.48vw;
}
.card-small {
    background: #f1eaf5;
    border-radius: 10px;
    display: inline-block;
    width: 1.5vw;
    height: 6vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-top: 0.3vw;
}
.card-small:hover {
    background: #f1eff2;
    transition: background-color 0.38s;
}
.card:hover {
    width: calc(21.4vw - 2px);
    height: calc(6vw - 2px);
}