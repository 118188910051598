.language-title {
    margin-top: 0.5vh;
    margin-left: 0.5vw;
    font-size: 3vh;
}

.groups {

}

.group {
    margin-left: 1vw;
}

.group-title {
    font-size: 1vw;
}

.country-regions {
    display: grid;
    grid-template-columns: repeat(5, calc(11vw + 12px));
    grid-gap: 2px;
}

.country-region {
    margin-top: -1.1vw;
}

.group-country-region-name {
    margin-left: 0.5vw;
    font-size: 0.8vw;
    font-weight: bold;
}

.group-country-region-language {
    margin-left: 0.5vw;
    margin-top: -0.8vw;
}

.group-country-region-language a {
    margin-right: 0.4vw;
    font-size: 0.7vw;
    text-decoration: none;
}

.group-country-region-language a:link, .group-country-region-language a:visited {
    color: #000000;
}

.group-country-region-language a:hover {
    color: #5d73e6;
}

.group-country-region-language a:active {
    color: #5d73e6;
}
