@charset "utf-8";
@import url('./element-style.css');

.navigator-hover {
    position: absolute;
    height: 22vh;
    width: 100vw;
    top: calc(5vh + 8px);
    z-index: 9999;
}








