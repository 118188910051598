@charset "utf-8";
@import url('./banner.css');

.banner2 {
    background-image: url('/public/beijing.jpg'), linear-gradient(to right, rgba(0, 255, 187, 0.5), rgba(220, 29, 29, 0.34));
}


.banner-title {
    margin-left: 2.5vw;
}

.banner-area {
    position: absolute;
    top: 83.5vh;
    right: 1vw;
}

.banner-describe {
    position: absolute;
    top: 11.5vh;
    margin-left: 2.5vw;
}

.banner-info {
    position: absolute;
    top: 59.5vh;
    width: 21vw;
    margin-left: 2.5vw;
}

.banner-area p {
    font-size: 0.6vw;
    color: lightblue;
    font-weight: bold;
}

.banner-info p {
    font-size: 0.9vw;
    color: white;
    font-weight: bold;
}

.banner-describe p {
    font-size: 0.8vw;
    font-weight: bold;
    color: white;
}

.banner h2 {
    font-size: 2.8vw;
    font-weight: bold;
    color: white;
}