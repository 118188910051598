.navigator-search input {
    height: 3vh;
    width: 16vw;
    padding-right: 10px; /* 确保文本不会覆盖背景图片 */
    padding-left: 10px; /* 确保文本不会覆盖背景图片 */

    border-color: lightpink;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;

    background-image: url('../../../../image/search.png'); /* 搜索图标的路径 */
    background-repeat: no-repeat; /* 背景图片不重复 */
    background-position: right 10px center; /* 背景图片位置 */
    background-size: 20px; /* 调整背景图片大小 */
}

