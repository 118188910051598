.navigator-global img,
.navigator-custom-service img,
.navigator-help img,
.navigator-user img {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 1vh;
    left: 1vh;
    height: 1.75vh;
    width: 1.75vh;
}