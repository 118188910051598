@charset "utf-8";
@import url('./element.css');

/* navigator element's layout style settings */
.navigator {
    height: 2vh;
    width: 100%;
    top: 1vh;
    position: absolute;
}

.navigator-0 {
    display: inline-block;
    height: 2vh;
    width: 15.4vh;
    position: absolute;
    left: calc( 2vw );
    padding-top: 1vh;
    padding-bottom: 1vh;

    align-items: center; /* 垂直居中 */
    text-align: center; /* 水平居中 */
    vertical-align: middle; /* 垂直居中 */
}

.navigator-0:hover {
    background: lightgrey;
    transition: background-color 0.38s;
    border-radius: 8px;
}

.navigator-1 {
    display: inline-block;
    height: 2vh;
    width: 8vh;
    position: absolute;
    left: calc( 2vw + 15.4vh + 20px );
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.navigator-1:hover {
    background: lightgray;
    transition: background-color 0.38s;
    border-radius: 8px;
}

.navigator-2 {
    display: inline-block;
    height: 2vh;
    width: 8vh;
    position: absolute;
    left: calc( 2vw + 23.4vh + 20px );
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.navigator-2:hover {
    background: lightgrey;
    transition: background-color 0.38s;
    border-radius: 8px;
}

.navigator-search {
    height: 3vh;
    width: 8vw;
    top: 0.2vh;
    margin-right: 1vw;
    position: absolute;
    right: calc(16vw + 6vh);
}

.navigator-global {
    height: 2vh;
    width: 2vh;
    position: absolute;
    right: 8vw;
    padding: 1vh 1vh 1vh 1vh;
}

.navigator-global:hover {
    background: lightgrey;
    transition: background-color 0.38s;
    border-radius: 8px;
}

.navigator-custom-service {
    height: 2vh;
    width: 2vh;
    position: absolute;
    right: 6vw;
    padding: 1vh 1vh 1vh 1vh;
}

.navigator-custom-service:hover {
    background: lightgrey;
    transition: background-color 0.38s;
    border-radius: 8px;
}

.navigator-help {
    height: 2vh;
    width: 2vh;
    position: absolute;
    right: 4vw;
    padding: 1vh 1vh 1vh 1vh;
}

.navigator-help:hover {
    background: lightgrey;
    transition: background-color 0.38s;
    border-radius: 8px;
}

.navigator-user {
    height: 2vh;
    width: 2vh;
    position: absolute;
    right: 2vw;
    padding: 1vh 1vh 1vh 1vh;
}

.navigator-user:hover {
    background: lightgrey;
    transition: background-color 0.38s;
    border-radius: 8px;
}

.navigator-global img,
.navigator-custom-service img,
.navigator-help img,
.navigator-user img {
    display: flex;
    align-items: center; /* 垂直居中 */
}