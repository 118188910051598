@charset "utf-8";
@import url("../../../entity/footer/layout.css");

/* navigator */

/* body */
.body-left {
    width: 26vw;
    height: 88vh;
    position: absolute;
    left: 2vw;
    top: 6vh;
}

.body-right {
    width: 66vw;
    height: 84vh;
    position: absolute;
    left: 29.5vw;
    top: 6vh;
    padding: 2vh 1vw;
}

/* footer */ /* rewrite */
.footer {
    left: 2vw;
    top: 95.6vh;
}