.banner-outbound {
    width: 26vw;
    height: 88vh;
    position: absolute;
    left: 2vw;
    top: 6vh;
}

.banner-container-inner {
    background-blend-mode: multiply;
    background-position: center;
    background-size: cover;
    border-radius: 15px;
}

.banner-container-inner h2 {
    font-size: 2.8vw;
    font-weight: bold;
    color: white;
}