.page-login-body-left {
    width: 67vw;
    height: 88vh;
    position: absolute;
    left: 2vw;
    top: 6vh;
}

.page-login-body-right {
    width: calc(26vw - 2px);
    height: calc(88vh - 2px);
    position: absolute;
    left: 71vw;
    top: 6vh;
}