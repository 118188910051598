/* card */
.card:hover {
    background-image: linear-gradient(to right, rgba(8, 0, 255, 0.10), #f1eaf5);
    /*background-image: #f1eff2;*/
    transition: background-color 0.38s;
    border: 1px solid mediumpurple;
}
.card:hover .card-logo {
    margin-top: calc(0.5vw - 1px);
    margin-left: calc(0.5vw - 1px);
}
.card:hover .card-right {
    margin-left: calc(6.6vw - 1px);
    margin-top: calc(-6vw - 1px);
}
/* card left */
.card-logo {
    height: 5vw;
    width: 5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    border-radius: 10px;
}
/* card right*/
.card-title {
    font-size: 0.7vw;
    font-weight: bold;
}
.card-description {
    font-size: 0.62vw;
}
.card-small a {
    font-size: 0.75vw;
    font-weight: bold;
    text-decoration: none;
}
.card-small a:link, .card-small a:visited {
    color: #000000;
}
.card-small a:hover {
    color: #5d73e6;
}
.card-small a:active {
    color: #5d73e6;
}
.card-small {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    display: table;
    text-align: center; /* 水平居中 */
    vertical-align: middle; /* 垂直居中 */
}

