.catalog {
    width: 66.9vw;
    height: 76vh;
    position: absolute;
    overflow-y: scroll;
    margin-top: 1vh;
    /*!* for Webkit browsers *!*/
    /*-webkit-scrollbar-track: initial;*/
    /*-webkit-scrollbar: initial;*/
}

/*!* 设置滚动条的样式 *!*/
/*.scrollable-container::-webkit-scrollbar {*/
/*    width: 12px; !* 滚动条的宽度 *!*/
/*}*/

/*.scrollable-container::-webkit-scrollbar-track {*/
/*    background: #f0f0f0; !* 滚动条轨道的颜色 *!*/
/*}*/

/*.scrollable-container::-webkit-scrollbar-thumb {*/
/*    background-color: #c1c1c1; !* 滚动条的滑块颜色 *!*/
/*    border-radius: 6px; !* 滑块的圆角 *!*/
/*}*/

/*!* 可选：如果你希望在其他浏览器上也有类似的效果，可以尝试以下样式 *!*/
/*.scrollable-container {*/
/*    -ms-overflow-style: scrollbar; !* IE和Edge浏览器 *!*/
/*}*/

/*.scrollable-container {*/
/*    scrollbar-width: thin; !* Firefox浏览器 *!*/
/*    scrollbar-color: #c1c1c1 #f0f0f0; !* Firefox浏览器，设置滚动条颜色和轨道颜色 *!*/
/*}*/