.navigator-hover {
    /* border */
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: lightpink;
    /* shadow */
    box-shadow: 4px 5px 5px rgba(0, 0, 0, 0.3);
    /* background */
    background: white;
    transition: background-color 0.50s;
}
/* hover internal */
.hover-area {
    margin-left: 2vw;
}
/* hover groups */
.hover-area-layout {
    position: absolute;
    top: 2.5vh;
}
.hover-area-layout-1 {
    display: grid;
    grid-template-columns: 14.5vw 16vw 16vw 16vw;
    gap: 3.5vw 2vw;
}
.hover-area-layout-2 {
    display: grid;
    grid-template-columns: 14.5vw 12vw 12vw 10vw 10vw 10vw 10vw;
    gap: 3.5vw 2vw;
}
/* */
/* hover group */
.hover-group {
    margin-top: -1vh;
    height: 20.5vh;
    width: 100%;
}
/* in each hover-group */
/** hover banner **/
.hover-banner {
    position: absolute;
    height: 18vh;
    width: 14.5vw;
    border-radius: 8px;
}
.hover-banner1 {
    /*background-image: url('/public/solution.jpg');*/
    background-image: linear-gradient(45deg, rgba(8, 2, 245, 0.3), rgba(0, 255, 187, 0.3));
}
.hover-banner2 {
    /*background-image: url('/public/application.jpg');*/
    background-image: linear-gradient(45deg, rgba(0, 255, 187, 0.5), rgba(8, 0, 255, 0.27));
}
.hover-banner-title {
    position: absolute;
    top: -0.5vh;
    left: 1.5vw;
    font-size: 1vw;
    font-weight: bold;
}
.hover-banner-desc {
    position: absolute;
    top: calc(0.5vh + 2vw);
    left: 1.5vw;
    height: 5vh;
    width: 90%;
    font-size: 0.6vw;
}
.hover-banner-link {
    position: absolute;
    top: calc(5.5vh + 3.2vw);
    left: 1.5vw;
    height: 10vh;
    width: 90%;
    font-size: 1vw;
}
/** hover group **/
.hover-title {
    position: absolute;
    top: -2.6vh;
    font-size: 0.8vw;
    font-weight: bold;
}
.hover-body {
    position: absolute;
    top: calc(-2.6vh + 1.2vw);
    height: 14vh;
    width: 16vw;
    word-break: normal;
    margin-top: 1vh;
    font-size: 0.8vw;
}
.hover-foot {
    position: absolute;
    height: 14vh;
    width: 100%;
    top: calc(14.4vh + 1.2vw);
    font-size: 0.8vw;
}
.hover-body ul,
.hover-foot ul {
    list-style-type: none;
    padding: 0;
}
.hover-body ul li,
.hover-foot ul li {
    margin-top: 0.2vw;
}
.hover-body a,
.hover-foot a,
.hover-banner-link p a {
    text-decoration: none;
}
.hover-body a,
.hover-foot a,
.hover-banner-link p a {
    font-size: 0.6vw;
    font-weight: bold;
    color: dimgray;
    text-decoration: none;
}
.hover-body a:link,
.hover-body a:visited,
.hover-foot a:link,
.hover-foot a:visited,
.hover-banner-link p a:link,
.hover-banner-link p a:visited {
    color: dimgray;
}
.hover-body a:hover,
.hover-banner-link p a:hover {
    color: #5d73e6;
}
.hover-body a:active {
    color: #5d73e6;
}

