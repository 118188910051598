.cards {
    width: 66vw;
    position: relative;
    margin-top: -2vh;
    margin-left: 0;
    margin-bottom: 1.2vh;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(3, calc(21.4vw + 10px));
    grid-gap: 2px;
}
