.footer {
    position: absolute;
    width: 96vw;
}

.footer-left {
    width: 5vw;
}

.footer-middle-0 {
    display: inline-block;
    width: 0.2vw;
    height: 1vh;
    margin-left: 0.8vw;
    margin-top: -10.5vh;
}

.footer-middle-1 {
    display: inline-block;
    margin-left: 0.8vw;
}

.footer-middle-2 {
    display: inline-block;
    margin-left: 0.8vw;
}

.footer-middle-3 {
    display: inline-block;
    margin-left: 0.8vw;
}

.footer-right {
    position: absolute;
    right: 0.5vw;
    height: 1vh;
    margin-top: -1.2vh;
}