@charset "utf-8";
@import url('./banner.css');

.banner4 {
    background-image: url('/public/shenyang.jpg'), linear-gradient(to right, rgba(0, 255, 187, 0.5), rgba(8, 0, 255, 0.27));
}


.banner-area {
    position: absolute;
    top: 83.5vh;
    right: 1vw;
}


