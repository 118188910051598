@charset "utf-8";
@import url('element/logo.css');
@import url('element/link.css');
@import url('element/search.css');
@import url('element/tarbar.css');


.navigator-small-screen {
    display: flex;
    align-items: center; /* 垂直居中 */
}

.navigator-middle-screen p {
    display: flex;
    align-items: center; /* 垂直居中 */
}

.navigator-big-screen p {
    display: flex;
    align-items: center; /* 垂直居中 */
}

.navigator-mega-screen p {
    display: flex;
    align-items: center; /* 垂直居中 */
    margin-left: auto;
    margin-right: auto;
}
