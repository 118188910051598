.footer-copyright {
    font-size: min(1.35vh, 16px);
}

.footer-borderline {
    font-size: min(1.3vh, 15px);
}

.footer-icp {
    font-size: min(0.89vh, 10px);;
    text-align: right;
}

.footer-link {
    font-size: min(1.3vh, 15px);
    font-weight: bold;
    text-decoration: none;
}

.footer-link:link, .footer-link:visited {
    color: #000000;
}

.footer-link:hover {
    color: #5d73e6;
}

.footer-link:active {
    color: #5d73e6;
}

