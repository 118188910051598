.dot-button {
    height: 1vw;
    width: 1vw;
    top: 47vh;
}

.dot-button-left {
    left: 0.4vw;
    position: absolute;
}

.dot-button-right {
    right: 0.4vw;
    position: absolute;
}