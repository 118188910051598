@charset "utf-8";
/* import banner-index-1 as banner1 */
/* import banner-index-2 as banner2 */
/* import banner-index-3 as banner3 */
@import url('../../../../../../../entity/banner/banner-index1.css');
@import url('../../../../../../../entity/banner/banner-index2.css');
@import url('../../../../../../../entity/banner/banner-index3.css');

.banner-container {
    background-blend-mode: multiply;
    background-position: center;
    background-size: cover;
    border-radius: 15px;
}